@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: 1px;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.hide-scrollbar {
  scrollbar-color: transparent;
  scrollbar-width: 0;
}

.carousel-wrapper {
  position: relative;
  margin-bottom: 40px; /* Adjust to make space for indicators */
}

/* Container for custom indicators */
.custom-indicators {
  display: flex;
  justify-content: center;
  margin-top: 20px; /* Adjust the space between the carousel and indicators */
}

/* Style for each dot */
.indicator-dot {
  font-size: 24px; /* Increase the size of the indicators */
  margin: 0 10px;   /* Spacing between indicators */
  cursor: pointer;
  color: gray;
  transition: color 0.3s;
}

.indicator-dot.active {
  color: #3b83ff; /* Active indicator color */
}